import React, { useEffect, useState } from 'react';
import './Subscribe.scss';
import Section from './Sections';
import Button from './Buttons';
import { useSubscribe } from '../services/ConvertKit.js';

const Subscribe = () => {
  const { subscribe, loading, success, error } = useSubscribe();
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (success) {
      setEmail(''); // Reset the email field upon successful subscription
    }
  }, [success]);

  const handleSubscribe = async (event) => {
    event.preventDefault();
    let email = event.target.email.value;
    await subscribe({ email });
  };

  const handleChange = (event) => {
    setEmail(event.target.value); // Update email state when input changes
  };

  return (
    <Section type="full" color="accent">
      <h2 className="title-md center">Subscribe to receive news</h2>
      <p className="paragraph-lg">Subscribe to our newsletter to receive our latest news and updates.</p>
      <div className="subscribe-form">
        {/* Display message div conditionally */}
        {success && <div className="alert alert-success">Subscription successful! Thank you for subscribing.</div>}
        {error && <div className="alert alert-error">An error occurred. Please try again.</div>}

        <form onSubmit={handleSubscribe}>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Your email address"
              name="email"
              value={email} // Set value to state
              onChange={handleChange} // Update state upon change
            />
          </div>
          {!loading && (
            <Button type="submit" size="lg">
              Subscribe
            </Button>
          )}
          {loading && <p>Loading...</p>}
        </form>
      </div>
    </Section>
  );
};

export default Subscribe;
