import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Section from '../Sections';
import Button from '../Buttons';

import './NotFoundPage.scss';

export default function NotFoundPage() {
  const [gifUrl, setGifUrl] = useState('');

  const loadRandomCatGif = async () => {
    try {
      const apiKey = process.env.REACT_APP_GIPHY_KEY;
      const gifQuery = 'cat';
      const response = await fetch(`https://api.giphy.com/v1/gifs/random?api_key=${apiKey}&tag=${gifQuery}`);
      const data = await response.json();
      const gifUrl = data.data.embed_url;
      setGifUrl(gifUrl);
    } catch (error) {
      console.error('Error al cargar el GIF:', error);
    }
  };

  useEffect(() => {
    loadRandomCatGif();
  }, []);

  return (
    <div className="not-found-container">
      <Section type="full">
        <div className="not-found-b1">
          <h1>Ups! No se encontró la página</h1>
          <h2>Mientras, te dejamos un gif </h2>
          <div className="gif-container">{gifUrl && <iframe title="404" src={gifUrl} alt="GIF" />}</div>
        </div>
        <div className="not-found-b1">
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <Button variant="secondary" size="lg">
              ← Volver a todos los productos
            </Button>
          </Link>
        </div>
      </Section>
    </div>
  );
}
