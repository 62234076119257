import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import Main from '../components/Main';
import ProductPage from '../components/productPage/ProductPage';
import CreatorPage from '../components/creatorPage/CreatorPage';
import TosPage from '../components/tosPage/TosPage';
import FaqPage from '../components/faqPage/FaqPage';
import NotFoundPage from '../components/notFoundPage/NotFoundPage';
import { initializeGA4, logPageView } from '../components/utils/analytics';

const AllLinks = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA4 only once
    if (!window.GA_INITIALIZED) {
      initializeGA4();
      window.GA_INITIALIZED = true;
    }

    // Log page view
    logPageView(location.pathname + location.search);
  }, [location]);
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/product/:productId" element={<ProductPage />} />
      <Route path="/creator/:creatorUsername" element={<CreatorPage />} />
      <Route path="/tos" element={<TosPage />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AllLinks;
