import React, { useState, useEffect, useRef } from 'react';

import { Link } from 'react-router-dom';
import ProductDataService from '../services/ProductsService';
import TagsDataService from '../services/TagsService';
import marletMainIconn from '../assets/market-main-icons.png';
import CircularProgress from '@mui/material/CircularProgress';
import Section from './Sections';
import Tags from './Tags';
import SearchBar from './SearchBar';
import ProductBox from './ProductBox';
import './Main.scss';
import Button from './Buttons';
import Subscribe from './Subscribe';

export default function Main() {
  const pageSize = 8;

  const [productsList, setProductsList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  // States: 'idle' | 'loading'
  const [state, setState] = useState('idle');
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debounceRef = useRef(null);

  const debounce = (func, delay) => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      func();
    }, delay);
  };

  useEffect(() => {
    const getTags = () => {
      TagsDataService.getAll()
        .then((response) => {
          setTags(Object.values(response.data));
        })
        .catch((e) => {
          console.log(e);
        });
    };
    getTags();
  }, []);

  const filterProducts = (updatedSelectedTags) => {
    setPage(0);
    setProductsList([]);
    setSelectedTags(updatedSelectedTags);
  };

  const searchProducts = (event) => {
    setPage(0);
    setProductsList([]);
    setSearchQuery(event.target.value);
  };

  // Used at render
  useEffect(() => {
    debounce(() => {
      let params = {};

      if (searchQuery) {
        params['query'] = searchQuery;
      }

      if (selectedTags) {
        params['tags'] = selectedTags;
      }

      if (page) {
        params['page'] = page;
      }

      if (pageSize) {
        params['size'] = pageSize;
      }

      ProductDataService.getAll(params)
        .then((response) => {
          const { products, totalPages } = response.data;
          setProductsList((productsList) => [...productsList, ...products]);
          setTotalPages(totalPages);
          setHasMore(page + 1 < totalPages);
          setState('idle');
        })
        .catch((e) => {
          console.log(e);
        });
    }, 500); // 500ms delay, adjust as needed
  }, [page, selectedTags, searchQuery]);

  // Used on the infinity scroll
  const fetchMoreData = () => {
    setState('loading');
    let newPage = page + 1;

    if (newPage > totalPages) {
      return false;
    }
    setPage(newPage);
  };

  return (
    <>
      <Section type="centered">
        <img className="illustration-sm" src={marletMainIconn} alt="orange globe with an arrow" />
        <h1 className="title-md">Encuentra los mejores productos y servicios de creadores/as latinoamericanos</h1>
        <SearchBar setSearchQuery={searchProducts} />
        <div className="tags-container">
          <Tags size="lg" selectionType="multi" tags={tags} onChangeSelection={filterProducts} />
        </div>
      </Section>
      <Section type="grid">
        {productsList &&
          productsList.map((product) => (
            <Link to={'product/' + product.slug} key={product.slug} style={{ textDecoration: 'none' }}>
              <ProductBox product={product} />
            </Link>
          ))}
      </Section>
      <Section>
        {state === 'idle' && (
          <Button style={{ display: hasMore ? '' : 'none' }} variant="tertiary-border" size="md" onClick={fetchMoreData}>
            Cargar más productos..
          </Button>
        )}

        {state === 'loading' && (
          <Button className="loader" variant="secondary">
            <CircularProgress color="inherit" />
          </Button>
        )}
      </Section>
      <Subscribe />
    </>
  );
}
