import React from 'react';
import PropTypes from 'prop-types';
import './Header.scss';

export default function Header({ creator, showPic }) {
  return (
    <div className="header">
      {showPic && <img className="creator-pic" src={creator?.profilePic} />}
      <h1 className="creator-name">
        {creator?.firstName} {creator?.lastName}
      </h1>
    </div>
  );
}

Header.propTypes = {
  creator: PropTypes.object,
  showPic: PropTypes.bool
};
