export function calcFrequency(paymentType, recurringInterval) {
  let recurring = '';
  if (paymentType != 'one_time') {
    const valueMap = {
      month: '/mes',
      year: '/año',
      week: '/semana',
      day: '/dia'
    };

    recurring = valueMap[recurringInterval];
  }

  return recurring;
}
