import React from 'react';
import PropTypes from 'prop-types';
import './SearchBar.scss';
import IconSearch from '@mui/icons-material/Search';

export default function SearchBar({ setSearchQuery }) {
  return (
    <div className="search-input">
      <input type="text" placeholder="Buscar producto o servicio" onChange={setSearchQuery} />
      <div className="icon-search">
        <IconSearch fontSize="small" />
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  setSearchQuery: PropTypes.func.isRequired
};
