import React from 'react';
import PropTypes from 'prop-types';
import './Sections.scss';

export default function Section(props) {
  const { type, color, children } = props;
  return (
    <div className={`section ${type === 'full' ? `color--${color}` : ''}`}>
      <div className={`section-content type--${type} color--${color}`}>{children}</div>
    </div>
  );
}

// Define defaultProps
Section.defaultProps = {
  type: 'centered',
  color: 'primary',
  children: null
};

// Define propTypes
Section.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node
};
