import http from '../http-common';

const getAll = (params) => {
  return http.get('/products', { params });
};

const get = (id) => {
  return http.get(`/products/${id}`);
};

const ProductsService = {
  getAll,
  get
};

export default ProductsService;
