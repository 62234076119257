import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import tags from './__mock__/tags.mock';
import products from './__mock__/products.mock';
import product from './__mock__/product.mock';
import creator from './__mock__/creator.mock';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-type': 'application/json'
  }
});
export default http;

const mockHandler = () => {
  const enableMockData = process.env.REACT_APP_ENABLE_MOCK === 'true';

  if (enableMockData) {
    const mock = new MockAdapter(http);

    mock.onGet('/tags', { page: 0, size: 4 }).reply(200, {
      ...tags
    });

    mock.onGet('/products', { page: 0, size: 4 }).reply(200, {
      ...products
    });

    mock.onGet('/products/very-long-title').reply(200, { ...product });

    mock.onGet('/creators/johansebastiaan').reply(200, { ...creator });
  }
};

mockHandler();
