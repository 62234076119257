import React from 'react';
import './Logo.scss';
import LogoHorizontal from '../assets/logo-creala.svg';
import LogoHW from '../assets/logo-h-w.svg';
import LogoCircle from '../assets/logo-creala-circle.svg';
import PropTypes from 'prop-types';

const Logo = ({ size, type, color }) => {
  return (
    <div className={`logo color--${color} size--${size}`}>
      {type === 'LogoHW' ? <img src={LogoHW} alt="" /> : ''}
      {type === 'horizontal' ? <img src={LogoHorizontal} alt="" /> : ''}
      {type === 'full' ? <img src={LogoCircle} alt="" /> : ''}
    </div>
  );
};
Logo.defaultProps = {
  size: 'md',
  type: 'horizontal',
  color: 'clear'
};

Logo.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string
};

export default Logo;
