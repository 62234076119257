import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Button from './Buttons';
import logo from '../assets/logo-creala.svg';
import './Navbar.scss';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.scrollY > 0;
      setIsSticky(isScrolling);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    if (window.innerWidth <= 767) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  //no scroll on mobile
  document.body.classList.toggle('no-scroll', isMenuOpen);

  return (
    <>
      <div className={`${isSticky ? 'nav-gap' : ''}`}></div>
      <div className={`navbar-container ${isSticky ? 'nav-sticky' : ''} ${isMenuOpen ? 'nav-open' : ''}`}>
        <nav className="navbar">
          <a href="/" className="brand">
            <img src={logo} alt="logo creala" />
          </a>
          <ul className="nav-items">
            <li className="nav-item" onClick={toggleMenu}>
              <Link to={'/faq'} style={{ textDecoration: 'none' }}>
                <Button variant="tertiary" size={isMenuOpen ? 'lg' : 'md'}>
                  Preguntas Frecuentes
                </Button>
              </Link>
            </li>
          </ul>
          <ul className="cta-items">
            <li className="cta-item" onClick={toggleMenu}>
              <Link to={'https://app.crea.la'} style={{ textDecoration: 'none' }}>
                <Button variant="primary" size={isMenuOpen ? 'lg' : 'md'}>
                  Publica tu producto
                </Button>
              </Link>
            </li>
          </ul>
          <div className="nav-menu" onClick={toggleMenu}>
            <Button variant="tertiary">{isMenuOpen ? <CloseIcon /> : <MenuIcon />}</Button>
          </div>
        </nav>
      </div>
    </>
  );
}
