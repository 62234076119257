import ReactGA from 'react-ga4';

export function initializeGA4() {
  ReactGA.initialize('G-EM44PL2ZWS');
}

export function logPageView(page) {
  if (process.env.NODE_ENV === 'production') {
    ReactGA.send({ hitType: 'pageview', page: page, title: page });
  }
}
