import React, { useState, useEffect, useCallback } from 'react';
import './FaqPage.scss';
import Section from '../../components/Sections';
import { useLocation } from 'react-router-dom';

const allQuestions = [
  {
    section: '💰 Cobros y Pago a Creadores',
    questions: [
      {
        id: 'question0',
        title: 'He realizado mi primera venta ¿Cuándo me llega el dinero?',
        content:
          'En la mayoría de los casos, el dinero llega dentro de las 72 horas posteriores a la venta en el Market. Usualmente puede tomarse un poco más de tiempo si la compra se realiza el viernes, sábado o domingo.'
      },
      {
        id: 'question1',
        title: '¿Cuáles son las comisiones de Creala?',
        content:
          'Por el momento, el 10% de comisión a productos que valen más de 20 USD. En el caso de productos que valen menos de 20 USD, Creala cobraría 2 USD de comisión.'
      },
      {
        id: 'question2',
        title: 'Cada cuanto Creala envía los pagos a los Creadores y Creadoras',
        content: 'Creala realiza pagos todos los días'
      },
      {
        id: 'question3',
        title: 'Mi pago fue cobrado por Creala LLC',
        content:
          'Creala LLC es una empresa que alberga a todos mis emprendimientos. Está basada en Delaware, Estados Unidos y utiliza los servicios de la procesadora de pagos Stripe para poder realizar los cobros, por este motivo Creala LLC aparece como beneficiaria de los pagos realizados.'
      }
    ]
  },
  {
    section: '💳 Vender un producto',
    questions: [
      {
        id: 'question4',
        title: '¿Cómo funciona el Market de Creala?',
        content:
          'Creala funciona a través de la procesadora de pagos Stripe, en donde generamos un link de pago y cuando se realiza una venta, transferimos los montos a tu banco local'
      },
      {
        id: 'question5',
        title: '¿Qué productos o servicios se pueden vender?',
        content:
          'Se puede vender productos y servicios que puedan ser consumidos por un medio digital. Cursos, Webinars, Video llamadas, Consultorias, Documentación, Podcast, Imagenes, Fotografías, Templates, Sitios Web, Plugins, etc. 🚨 En esta etapa no se pueden vender productos o bienes físicos 🚨'
      },
      {
        id: 'question6',
        title: '¿Cómo puedo vender mejor?',
        content:
          'Hemos notado que los creadores/as con más ventas son los que tienen una audiencia. Estos creadores/as generan publicaciones en sus redes sociales (Facebook, Youtube, Twitter, Instagram, TikTok y en otros más) en donde comparten el link de sus productos o servicios en el Market de Creala. Mientras más van generando publicaciones acerca de sus productos o servicios que están a la venta, más posibilidades tienen de vender. Creala también va a buscar exponer y potenciar a los creadores con algunos servicios y características.'
      },
      {
        id: 'question7',
        title: '¿Qué se puede vender en el Market de Creala',
        content: (
          <>
            <p>👉 Cualquier producto o servicio que pueda ser usado a través de un medio digital.</p>
            <p>🛍️ En nuestro marketplace puedes vender todos estos productos:</p>
            <p>📄 PDFs (instructivos, documentaciones)</p>
            <p>📹 Videos (Cursos, webinars)</p>
            <p>📑 Plantillas (templates)</p>
            <p>🎓 Cursos (de todo)</p>
            <p>🍴 Recetas</p>
            <p>💄 Técnicas de Maquillaje</p>
            <p>🎯 Coaching</p>
            <p>💰 Asesoría en Finanzas</p>
            <p>📚 eBooks</p>
            <p>🎶 Música, composiciones</p>
            <p>🌎 Traducciones</p>
            <p>📊 Asesorías y Consultorías</p>
            <p>📞 Citas para video-llamadas</p>
            <p>📷 Imágenes (wallpapers, fotos)</p>
            <p>🖥️ Servicios digitales</p>
            <p>🔍 Investigaciones y artículos</p>
            <p>🧩 Plugins y apps</p>
            <p>🎨 Arte Digital</p>
            <p>🎬 Tutoriales y mucho más... </p>
          </>
        )
      },
      {
        id: 'question8',
        title: '¿Qué pasa luego de aplicar a ambos formularios?',
        content: (
          <div>
            <p>
              Luego de que hayas completados ambos formularios (el de tu perfil de Creador y el de tu producto o servicio), subiremos tu
              producto o servicio con la descripción de &quot;Próximamente&quot; al{' '}
              <a href="https://market.crea.la/" target="_blank" rel="noopener noreferrer">
                https://market.crea.la/
              </a>
            </p>
            <p>Al mismo tiempo, te solicitaremos a través de un correo electrónico:</p>
            <ul>
              <li>Una fotografía de tu cédula de identidad en color (del frente y parte posterior).</li>
              <li>Tu cuenta bancaria</li>
              <li>Cualquier otro dato faltante para habilitar tu producto o servicio a la venta en el Market.</li>
            </ul>
          </div>
        )
      }
    ]
  },
  {
    section: '🌐 General',
    questions: [
      {
        id: 'question9',
        title: '¿Esto es para creadores de contenido o sirve para freelancers?',
        content:
          'Dentro de “Creadores de productos y servicios digitales” puedes se incluyen todo tipo de productos o servicios que pueden ser reproducidos en un medio digital.'
      },
      {
        id: 'question10',
        title: '¿Cuál es la dirección (URL) del Market de Creala?',
        content: 'La dirección o URL de nuestro market es: https://market.crea.la/'
      },
      {
        id: 'question11',
        title: 'Acerca de productos físicos o servicios presenciales',
        content:
          'Por el momento, no podemos ofrecer bienes físicos, ni tampoco servicios que requieran presencia física en el Market de Creala. Ya que estos productos y/o servicios tienen responsabilidades que como negocio no podemos abarcar por ahora.'
      },
      {
        id: 'question12',
        title: '¿Cómo puedo editar un producto o servicio ya publicado?',
        content:
          'Si eres un creador/a con un producto o servicio ya públicado y quieres editarlo, simplemente escribe un correo a market@crea.la identificando tu producto o servicio y las modificaciones que quieres hacer'
      }
    ]
  }
];

export default function FaqPage() {
  const location = useLocation();
  // Initial state for every question is 'not expanded'
  const [expandedQuestions, setExpandedQuestions] = useState(allQuestions.flatMap((qSection) => qSection.questions.map(() => false)));

  const toggleQuestion = useCallback(
    (index) => {
      // Toggle the expanded state for the clicked question
      setExpandedQuestions(expandedQuestions.map((expanded, i) => (i === index ? !expanded : expanded)));
    },
    [expandedQuestions]
  ); // the dependency for useCallback

  useEffect(() => {
    if (location.hash) {
      toggleQuestion(parseInt(location.hash.replace(/\D/g, ''), 10));
    }
  }, []);

  return (
    <Section type="faq-page">
      <div className="faq">
        <h1 className="faq__title">Preguntas Frecuentes</h1>
        <p className="faq__description">
          Todo lo que necesitas saber sobre Creala. Si tienes alguna otra duda, puedes escribirnos a market@crea.la
        </p>
        <hr className="faq__separator" />
        <div className="faq__columns">
          {allQuestions.map((section, sectionIndex) => (
            <div key={sectionIndex} className="faq__column">
              <h2 className="faq__section-title">{section.section}</h2>
              {section.questions.map((q, questionIndex) => {
                const globalIndex = allQuestions
                  .slice(0, sectionIndex)
                  .reduce((sum, prevSection) => sum + prevSection.questions.length, questionIndex);
                return (
                  <div key={questionIndex} className="faq__question">
                    <h3 id={q.id} className={expandedQuestions[globalIndex] ? 'expanded' : ''} onClick={() => toggleQuestion(globalIndex)}>
                      <div className={`toggle ${expandedQuestions[globalIndex] ? 'expanded' : ''}`}>
                        <div className="arrow"></div>
                      </div>
                      {q.title}
                    </h3>
                    {expandedQuestions[globalIndex] && <p>{q.content}</p>}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}
