import React from 'react';
import PropTypes from 'prop-types';
import './Buttons.scss';

export default function Button(props) {
  const { variant, size, children, ...rest } = props;

  return (
    <button className={`button button--${variant} button--${size}`} {...rest}>
      {children}
    </button>
  );
}

Button.defaultProps = {
  variant: 'primary',
  size: 'md'
};

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node
};
