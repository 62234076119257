import axios from 'axios';
import { useState } from 'react';

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_CONVERTKIT_API_URL}`
});

httpClient.defaults.headers.common['Accept'] = 'application/json';
httpClient.defaults.headers.common['Content-Type'] = 'application/json';
httpClient.defaults.timeout = 5000;

export const useSubscribe = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const subscribe = async (input) => {
    setLoading(true);
    setError(null);

    try {
      const inputBody = {
        ...input,
        api_key: process.env.REACT_APP_CONVERTKIT_API_KEY
      };

      const response = await httpClient.post(`forms/5698199/subscribe`, inputBody);
      setSuccess(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { subscribe, loading, success, error };
};
