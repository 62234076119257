import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import './Tags.scss';

export default function Tags(props) {
  const { tags, size, selectionType, onChangeSelection } = props;
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTag = (tag) => {
    if (selectionType === 'single') {
      setSelectedTags([tag]);
    } else if (selectionType === 'multi') {
      const updatedTags = selectedTags.includes(tag) ? selectedTags.filter((selectedTag) => selectedTag !== tag) : [...selectedTags, tag];
      setSelectedTags(updatedTags);
      if (onChangeSelection) {
        onChangeSelection(updatedTags.map((obj) => obj.id));
      }
    }
  };

  return (
    <>
      {tags &&
        tags.length > 0 &&
        tags.map((tag) => (
          <div
            className={`tag tag-${size} tag-${selectionType} ${selectedTags.includes(tag) ? 'selected' : ''}`}
            key={tag.id}
            onClick={() => toggleTag(tag)}
          >
            <p>{tag.name}</p>
          </div>
        ))}
    </>
  );
}

Tags.defaultProps = {
  size: 'sm',
  selectionType: 'single'
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired, // `tags` is an array of objects with `id` and `name`
  size: PropTypes.string, // `size` is a string
  selectionType: PropTypes.oneOf(['single', 'multi']), // `selectionType` must be either 'single' or 'multi'
  onChangeSelection: PropTypes.func // `onChangeSelection` is a function
};
