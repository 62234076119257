import http from '../http-common';

const getAll = (params) => {
  return http.get('/tags', { params });
};

const TagsService = {
  getAll
};

export default TagsService;
