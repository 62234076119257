import React from 'react';
import './Footer.scss';
import yt from '../assets/icon-youtube.svg';
import it from '../assets/icon-instagram.svg';
import wp from '../assets/icon-whatsapp.svg';
import tw from '../assets/icon-twitter.svg';
import ds from '../assets/icon-discord.svg';
import Logo from './Logo';
import Section from './Sections';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <Section color="secondary" type="full">
      <div className="footer-grid">
        <div className="footer-brand">
          <a className="link" href="/">
            <Logo type="LogoHW" color="clear" size="sm" />
          </a>
        </div>
        <div className="footer-faq list-group">
          <h3 className="title-sm">Preguntas frecuentes</h3>
          <ul className="list">
            <li className="list-item">
              <Link className="link" to="/faq#question0">
                He realizado mi primera venta ¿Cuándo me llega el dinero?
              </Link>
            </li>
            <li className="list-item">
              <Link className="link" to="/faq#question1">
                ¿Cuáles son las comisiones de Creala?
              </Link>
            </li>
            <li className="list-item">
              <Link className="link" to="/faq#question2">
                Cada cuanto Creala envía los pagos a los Creadores y Creadoras
              </Link>
            </li>
            <li className="list-item">
              <Link className="link" to="/faq#question4">
                ¿Cómo funciona el Market de Creala?
              </Link>
            </li>
            <li className="list-item">
              <Link className="link" to="/faq#question8">
                ¿Qué pasa luego de aplicar a ambos formularios?
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-countries list-group">
          <h3 className="title-sm">Países habilitados</h3>
          <ul className="list">
            <li className="list-item">
              <a className="link" href="/">
                🇵🇾 Paraguay
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-social list-group">
          <ul className="list">
            <li className="list-item">
              <img src={yt} alt="youtube" />
              <a className="link" href="https://www.youtube.com/@creala_" target="_blank" rel="noreferrer">
                Youtube
              </a>
            </li>
            <li className="list-item">
              <img src={it} alt="instagram" />
              <a className="link" href="https://instagram.com/crea7a" target="_blank" rel="noreferrer">
                Instagram
              </a>
            </li>

            <li className="list-item">
              <img src={tw} alt="twitter" />
              <a className="link" href="https://twitter.com/creala_" target="_blank" rel="noreferrer">
                X (Twitter)
              </a>
            </li>
            <li className="list-item">
              <img src={wp} alt="whatsapp" />
              <a className="link" href="https://wa.me/17863055080" target="_blank" rel="noreferrer">
                Whatsapp
              </a>
            </li>
            <li className="list-item">
              <img src={ds} alt="discord" />
              <a className="link" href="https://discord.gg/TSjKKUjTMW" target="_blank" rel="noreferrer">
                Únete al Discord
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-copyright">
          <a className="link" href="https://crea.la/">
            Creala LLC Copyright © {new Date().getFullYear()}.
          </a>
        </div>
      </div>
    </Section>
  );
}
