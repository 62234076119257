import React from 'react';
import PropTypes from 'prop-types';
import './Breadcrumb.scss';

export default function Breadcrumb(props) {
  const { variant, children, ...rest } = props;

  return (
    <button className={`breadcrumb breadcrumb--${variant} `} {...rest}>
      {children}
    </button>
  );
}

Breadcrumb.defaultProps = {
  variant: 'primary'
};

Breadcrumb.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node
};
