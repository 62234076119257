import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Slider.scss';

import { ReactComponent as ChevronLeft } from '../assets/materialSymbols/chevron_left.svg';
import { ReactComponent as ChevronRight } from '../assets/materialSymbols/chevron_right.svg';

export default function Slider({ images, showThumbnails, showDots, showArrows }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="slider-container">
      <div className="slider">
        {images.map((image, index) => (
          <div className={index === currentIndex ? 'slide active' : 'slide'} key={index}>
            <img src={image.imageURL} alt={`Slide ${index}`} />
          </div>
        ))}
        {showArrows && (
          <div className="slider-nav">
            <button className="slider-prev" onClick={goToPrevSlide}>
              <ChevronLeft style={{ color: 'white' }} />
            </button>

            <button className="slider-next" onClick={goToNextSlide}>
              <ChevronRight style={{ color: 'white' }} />
            </button>
          </div>
        )}
      </div>
      {showDots && (
        <div className="slider-dots">
          {images.map((_, index) => (
            <button className={index === currentIndex ? 'dot active' : 'dot'} key={index} onClick={() => goToSlide(index)} />
          ))}
        </div>
      )}
      {showThumbnails && (
        <div className="slider-thumbnails">
          {images.map((image, index) => (
            <div className={index === currentIndex ? 'thumbnail active' : 'thumbnail'} key={index} onClick={() => goToSlide(index)}>
              <img src={image.imageURL} alt={`Thumbnail ${index}`} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Slider.defaultProps = {
  images: [],
  showArrows: true,
  showThumbnails: true,
  showDots: false
};

Slider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      imageURL: PropTypes.string.isRequired // Each image object must have an imageURL string
    })
  ).isRequired, // images is an array of such objects and is required
  showThumbnails: PropTypes.bool, // showThumbnails is a boolean
  showDots: PropTypes.bool, // showDots is a boolean
  showArrows: PropTypes.bool // showArrows is a boolean
};
