import http from '../http-common';

const get = (id) => {
  return http.get(`/creators/${id}`);
};

const CreatorsService = {
  get
};

export default CreatorsService;
