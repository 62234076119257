import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ProductBox.scss';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import defaultProductImage from '../assets/defaultProduct.png';
import defaultAccountImage from '../assets/avatar.png';
import Tags from './Tags';
import { calcFrequency } from '../utils';

export default function ProductBox({ product, hideCreator = false }) {
  return (
    <div className="product">
      <img
        className="productImage"
        src={product.pics?.length > 0 ? product.pics[0].imageURL : defaultProductImage}
        alt={product.pics?.length > 0 ? product.pics[0].alt : 'No picture for product'}
      />
      <div className="productPrice">
        <p className="price">{`$ ${parseInt(product.price?.price) / 100} ${calcFrequency(product.price?.paymentType, product.price?.recurringInterval)}`}</p>
      </div>
      <div className="productDescription">
        <p className="productTitle">{product.name}</p>
        <div className="productTags">
          <Tags tags={product.tags} />
        </div>
        {!hideCreator && (
          <div className="author">
            <Link to={'/creator/' + product.account.userName} style={{ textDecoration: 'none' }} className="author">
              <img
                src={product.account?.profilePicture?.length > 0 ? product.account?.profilePicture : defaultAccountImage}
                alt={product.account?.firstName}
                className="authorPicture"
              />
              <p className="authorName">{product.account?.firstName}</p>
            </Link>
          </div>
        )}
        <ArrowForwardIcon />
      </div>
    </div>
  );
}

ProductBox.propTypes = {
  product: PropTypes.shape({
    pics: PropTypes.arrayOf(
      PropTypes.shape({
        imageURL: PropTypes.string.isRequired,
        alt: PropTypes.string
      })
    ).isRequired,
    price: PropTypes.shape({
      price: PropTypes.string.isRequired,
      paymentType: PropTypes.string,
      recurringInterval: PropTypes.string
    }),
    name: PropTypes.string.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object),
    account: PropTypes.shape({
      profilePicture: PropTypes.string,
      firstName: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired
    })
  }).isRequired,
  hideCreator: PropTypes.bool
};
