import React from 'react';
import './TosPage.scss';

export default function TosPage() {
  return (
    <div className="tos-container">
      <div className="tos-p item1">
        <h1>Terms of Service</h1>
        <p>
          Toky, Inc. (“Toky”, “we” or “us”) offers various services to you through our website (toky.co) and our application on the web, all
          of which are conditioned on your agreement to adhere to the following Terms of Service without modification of any kind. Your use
          of the Service and/or your registration with us constitutes your agreement to these Terms of Service. These Terms of Service are
          subject to change at any time, without prior notice. Any changes that are made to these Terms of Service will not apply
          retroactively and will not apply to disputes or events occurring before the change is published. You are responsible for reviewing
          these Terms of Service on a regular basis. Your continued use of Toky after such changes have been posted constitute your binding
          acceptance of such changes. These Terms of Service apply to all visitors and all who access our website or Services.
        </p>
        <p>
          PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS AGREEMENT CONTAINS A MANDATORY INDIVIDUAL
          ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
          DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. IF YOU REGISTER FOR A FREE TRIAL OF OUR SERVICE, THESE TERMS WILL ALSO GOVERN
          THAT FREE TRIAL.
        </p>
        <p>
          We respect the privacy and security of our Users. You understand that by using our Services, you give consent to the collection,
          use and disclosure of your personally identifiable information as well as any non-personally identifiable information.
        </p>
      </div>
      <div>
        <h3>1. Services</h3>
        <p>
          Subject to the terms and conditions of this Agreement, Toky will provide you with non-exclusive access to the Services during the
          term of this Agreement – solely for use for your own, internal business purposes. The “Service” includes (a) the Site, (b) Apps
          and (c) other services provided to you through www.toky.co (“Site”) based on the plan purchased, including all software, data,
          text, images, sounds, videos, and other content made available through the Site. Any new features added to or augmenting the
          Services are also subject to this Agreement, it being understood and agreed that new features may not be available for all pricing
          plans. The Services may also include basic or expanded support based on the Service plan purchased. Toky will use reasonable
          efforts to make the Services available except for: (a) planned downtime, (b) emergency maintenance, or (c) any unavailability
          caused by circumstances beyond our reasonable control, such as, but not limited to, major disruptions in the providers we use,
          acts of God, acts of government, acts of terror or civil unrest.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your login and account, and are fully responsible for any and all
          activities that occur under your login or account. You agree and acknowledge that your login may only be used by one (1) person,
          and that you will not share a single login among multiple people.
        </p>
      </div>
    </div>
  );
}
